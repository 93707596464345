import React from "react";
import PropTypes from "prop-types";
import "./LogoBrand.scss";
import { brandPropTypes } from "app/utils/propTypes";
import { withRouter } from "react-router";
import { sendTagOnLogoRedirect } from "app/utils/analytics";

class LogoBrand extends React.PureComponent {
	constructor(props) {
		super(props);
		this.onClick = this.onClick.bind(this);
	}

	onClick() {
		const { location } = this.props;
		sendTagOnLogoRedirect(location.pathname);
	}

	render() {
		const { shop = "", brand = "", isWhite = false } = this.props;

		return (
			<i
				onClick={this.onClick}
				className={`logo-brand logo-brand--${brand}-${shop}${isWhite ? "-white" : ""}`}
			/>
		);
	}
}

LogoBrand.propTypes = {
	shop: PropTypes.string,
	isWhite: PropTypes.bool,
	brand: brandPropTypes,
};

export default withRouter(LogoBrand);
