import axios from "axios";
import env from "app/utils/env";
import { getCredentials } from "app/utils/auth";
import { FETCH_LOOKALIKE_PRODUCTS } from "app/pages/.shared/LookAlikeProduct/lookAlikeProductActionTypes";

export const fetchLookAlikeProducts = id => {
	const { token } = getCredentials();
	const headers = {
		Authorization: token,
		"Content-type": "application/json",
	};

	return {
		type: FETCH_LOOKALIKE_PRODUCTS,
		promise: axios.get(`${env("NAV_LOGS_API_URL")}/reco?id=${id}`, { headers }),
	};
};
