import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./MerchCountdown.scss";

const MerchCountdown = ({ days, hours, minutes, seconds }) => {
	return (
		<div className="countdown">
			{days && (
				<React.Fragment>
					<div className="countdown__value">
						<div className="countdown__time">{days}</div>
						<div className="countdown__time__suffix">
							<FormattedMessage id="countdown.days.short" />
						</div>
					</div>
					<div className="countdown__separator">:</div>
				</React.Fragment>
			)}
			<div className="countdown__value">
				<div className="countdown__time">{hours}</div>
				<div className="countdown__time__suffix">
					<FormattedMessage id="countdown.hours.short" />
				</div>
			</div>
			<div className="countdown__separator">:</div>
			<div className="countdown__value">
				<div className="countdown__time">{minutes}</div>
				<div className="countdown__time__suffix">
					<FormattedMessage id="countdown.minutes.short" />
				</div>
			</div>
			<div className="countdown__separator">:</div>
			<div className="countdown__value">
				<div className="countdown__time">{seconds}</div>
				<div className="countdown__time__suffix">
					<FormattedMessage id="countdown.seconds.short" />
				</div>
			</div>
		</div>
	);
};

MerchCountdown.defaultProps = {
	days: "00",
	hours: "00",
	minutes: "00",
	seconds: "00",
};

MerchCountdown.propTypes = {
	days: PropTypes.string,
	hours: PropTypes.string,
	minutes: PropTypes.string,
	seconds: PropTypes.string,
};

export default MerchCountdown;
