import React, { Component } from "react";
import PropTypes from "prop-types";
import Loader from "app/pages/.shared/Loader";
import "./loading-placeholder.scss";

class LoadingPlaceholder extends Component {
	render() {
		const { message } = this.props;

		return (
			<div className="loading-placeholder">
				{message && <p className="loading-placeholder__message">{message}</p>}
				<Loader />
			</div>
		);
	}
}

LoadingPlaceholder.propTypes = {
	message: PropTypes.string,
};

export default LoadingPlaceholder;
