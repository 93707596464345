import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { productShape, resolutionPropType } from "app/utils/propTypes";
import LookAlikeProduct from "app/pages/.shared/LookAlikeProduct/LookAlikeProduct";
import "./LookAlikeProducts.scss";
import { Waypoint } from "react-waypoint";
import { sendTagOnLookAlikeProductsSeenClick } from "app/utils/analytics";

const LookAlikeProducts = ({ products, resolution }) => {
	const waypointScrollableAncestor = typeof window !== "undefined" ? window : undefined;

	const sentTagOnView = () => {
		sendTagOnLookAlikeProductsSeenClick({ products });
	};

	return (
		products.length > 0 && (
			<div className="lookalike-products">
				<h4 className="lookalike-products__title">
					<FormattedMessage id="lookalike.products.title" />
				</h4>
				<Waypoint scrollableAncestor={waypointScrollableAncestor} onEnter={sentTagOnView}>
					<div className="lookalike-products__list">
						{products.map((product, index) => {
							return (
								<div key={product.id} className="lookalike-products__item">
									<LookAlikeProduct
										product={product}
										resolution={resolution}
										position={index}
									/>
								</div>
							);
						})}
					</div>
				</Waypoint>
			</div>
		)
	);
};

LookAlikeProducts.propTypes = {
	products: PropTypes.arrayOf(productShape),
	resolution: resolutionPropType,
};

LookAlikeProducts.defaultProps = {
	products: [],
};

export default LookAlikeProducts;
