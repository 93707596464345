import React from "react";

const Loader = () => {
	return (
		<img
			style={{ height: "100px", width: "100px" }}
			src="/static/icons/loader.gif"
			alt="loader"
		/>
	);
};

export default Loader;
