/*eslint no-undef: "error"*/
import React from "react";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";
import get from "lodash/get";
import { connect } from "react-redux";
const IMG_SIZES = {
	large: { width: 1440 },
	xlarge: { width: 1440 },
	medium: { width: 1440 },
};

const IMG_SIZES_SMALL = {
	small: { width: 768 },
};

const CLOUDINARY_OPTIONS = ["c_crop"];

const CLOUDINARY_OPTIONS_MEDIUM = ["c_fill", "g_center"];

const ListingHeaderImageComponent = ({ resolution, imgSrc }) => {
	if (resolution === RESOLUTION.LARGE || resolution === RESOLUTION.XLARGE) {
		return (
			<ProgressiveImg
				sizes={IMG_SIZES}
				cloudinaryOptions={CLOUDINARY_OPTIONS}
				src={get(imgSrc, "desktop.src")}
				thumbnail={get(imgSrc, "desktop.thumbnail")}
			/>
		);
	} else if (resolution === RESOLUTION.MEDIUM) {
		return (
			<ProgressiveImg
				sizes={IMG_SIZES}
				cloudinaryOptions={CLOUDINARY_OPTIONS_MEDIUM}
				src={get(imgSrc, "desktop.src")}
				thumbnail={get(imgSrc, "desktop.thumbnail")}
			/>
		);
	} else if (resolution === RESOLUTION.SMALL) {
		return (
			<ProgressiveImg
				sizes={IMG_SIZES_SMALL}
				cloudinaryOptions={CLOUDINARY_OPTIONS}
				src={get(imgSrc, "mobile.src")}
				thumbnail={get(imgSrc, "mobile.thumbnail")}
			/>
		);
	}
	return false;
};

const mapStateToProps = state => ({
	resolution: state.resolution,
});

export const ListingHeaderImage = connect(mapStateToProps)(ListingHeaderImageComponent);
