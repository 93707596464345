import PropTypes from "prop-types";
import React, { useCallback } from "react";
import GoogleMap from "google-map-react";
import { connect } from "react-redux";
import IconPin from "app/pages/.shared/icons/static/icons/IconPin";
import { getCloudinaryFetchUrl } from "app/utils/image/cloudinaryUtils";
import { getGoogleMapsStaticUrlForCoords } from "app/utils/utils";
import env from "app/utils/env";

const GOOGLE_API_KEY = env("GOOGLE_API_KEY");
/*
 * Marge négative pour que le pin soit toujours bien positionné même au zoom/dezoom
 * @see https://github.com/istarkov/google-map-react/issues/168
 */
const PIN_STYLE = {
	height: "43px",
	width: "30px",
	marginTop: "-40px",
	marginLeft: "-20px",
};

const Map = ({
	longitude,
	latitude,
	zoom,
	mapTypeControl,
	streetViewControl,
	resolution,
	shop,
}) => {
	const [isGoogleMapOpen, showGoogleMap] = React.useState(false);
	const [containerDimension, setContainerDimension] = React.useState({ width: 0, height: 0 });
	const [staticMapUrl, setStaticMapUrl] = React.useState(undefined);
	const memoizedShowGoogleMapFunction = React.useCallback(() => showGoogleMap(true), []);

	const language = shop.slice(0, 2); // @see https://developers.google.com/maps/faq#languagesupport
	const region = shop.slice(3, 5);

	// https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
	const measuredRef = useCallback(node => {
		if (node !== null) {
			setContainerDimension({
				height: node.getBoundingClientRect().height,
				width: node.getBoundingClientRect().width,
			});
		}
	}, []);

	React.useEffect(() => {
		const staticMapGoogleUrl = getGoogleMapsStaticUrlForCoords({
			latitude,
			longitude,
			resolution,
			options: {
				height: containerDimension.height,
				width: containerDimension.width,
				zoom,
				GOOGLE_API_KEY,
			},
		});
		const cloudinaryUrl = getCloudinaryFetchUrl(staticMapGoogleUrl);

		setStaticMapUrl(cloudinaryUrl);
	}, [latitude, longitude, resolution, containerDimension]);

	return staticMapUrl ? (
		<div
			ref={measuredRef}
			onClick={memoizedShowGoogleMapFunction}
			style={{
				cursor: "pointer",
				height: "100%",
				position: "relative",
				// @todo: ceci est un quickfix en attendant de revoir le layout d'une page produit. en effet, cette div est en dessous (littéralement) de toute la section. Visuellement ça ne se voit pas
				// mais je l'ai remarqué ici parce que j'ai dût mettre un onClick sur cette div.
				zIndex: 2,
				background: `url(${staticMapUrl}) no-repeat center`,
			}}
		>
			{isGoogleMapOpen && (
				<GoogleMap
					options={maps => ({
						scrollwheel: false,
						fullscreenControl: true,
						fullscreenControlOptions: {
							position: maps.ControlPosition.BOTTOM_RIGHT,
						},
						mapTypeControl: mapTypeControl,
						mapTypeControlOptions: {
							position: maps.ControlPosition.TOP_LEFT,
						},
						streetViewControl: streetViewControl,
						scaleControl: true,
					})}
					bootstrapURLKeys={{
						key: GOOGLE_API_KEY,
						language,
						region,
					}}
					center={{ lat: latitude, lng: longitude }}
					zoom={zoom}
				>
					<div lat={latitude} lng={longitude}>
						<IconPin style={PIN_STYLE} />
					</div>
				</GoogleMap>
			)}
		</div>
	) : null;
};

Map.defaultProps = {
	mapTypeControl: false,
	streetViewControl: false,
	shop: "",
};

Map.propTypes = {
	mapTypeControl: PropTypes.bool,
	streetViewControl: PropTypes.bool,
	shop: PropTypes.string,
	resolution: PropTypes.string,
	longitude: PropTypes.number,
	latitude: PropTypes.number,
	zoom: PropTypes.number, // 0 à 21
};

const mapStateToProps = state => {
	return {
		shop: state.shop,
		resolution: state.resolution,
	};
};

export default connect(mapStateToProps)(Map);
